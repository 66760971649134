.size-register {
  width: 25%;
}

@media screen and (max-width: 1700px) {
  .size-register {
    width: 30%;
  }
}

@media screen and (max-width: 1240px) {
  .size-register {
    width: 35%;
  }
}

@media screen and (max-width: 1000px) {
  .size-register {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .size-register {
    width: 60%;
  }
}

@media screen and (max-width: 450px) {
  .size-register {
    width: 80%;
  }
}
