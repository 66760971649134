.App {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

.show-menu-for-desktop {
  display: contents;
}

.show-menu-for-mobile {
  display: none;
}

.show {
  width: 230px;
  height: 100%;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.hide {
  width: 100px;
  height: 100%;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

@media screen and (max-width: 600px) {
  .show-menu-for-desktop {
    display: none;
  }

  .show-menu-for-mobile {
    display: contents;
  }

  .hide {
    display: none;
  }

  .show {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    z-index: 999;
    background-color: white;
  }
}
