.profile {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.between {
  padding-top: 0;
}

.form {
  display: flex;
  flex-direction: column;
}

.profile-first-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
}

.referal-link {
  width: 180px;
  background-color: #c5efff;
  margin-left: 10px;
}

.choice-for-desktop {
  display: flex;
  flex-direction: row;
}

.choice-for-mobile {
  display: none;
}

@media screen and (min-width: 1625px) {
  .profile {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .choice-for-desktop {
    display: flex;
    flex-direction: row;
  }

  .choice-for-mobile {
    display: none;
  }
}

@media screen and (min-width: 1400px) {
  .profile {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  .choice-for-desktop {
    display: flex;
    flex-direction: row;
  }

  .choice-for-mobile {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .profile {
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  .choice-for-desktop {
    display: flex;
    flex-direction: row;
  }

  .choice-for-mobile {
    display: none;
  }
}

@media screen and (max-width: 1255px) {
  .profile-first-name {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .between {
    padding-top: 20px;
  }

  .for-center {
    display: flex;
    justify-content: center;
  }

  .choice-for-desktop {
    display: flex;
    flex-direction: row;
  }

  .choice-for-mobile {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .profile {
    display: flex;
    flex-direction: column;
    width: 95%;
  }

  .profile-first-name {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .between {
    padding-top: 20px;
  }

  .for-center {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .referal-link {
    width: 100px;
    background-color: #c5efff;
    margin-left: 10px;
  }
}

@media screen and (max-width: 520px) {
  .choice-for-desktop {
    display: none;
  }

  .choice-for-mobile {
    display: flex;
    flex-direction: column;
  }
}
