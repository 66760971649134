.fake-div {
  width: 100px;
}

.question-title {
  padding-left: 0;
}

@media screen and (max-width: 650px) {
  .fake-div {
    display: none;
  }

  .question-title {
    padding-left: 20px;
  }
}
