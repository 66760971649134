.empty-data {
  display: flex;
  justify-content: center;
}

.center {
  display: flex;
  flex-direction: row;
}

.space-text {
  padding-top: 180px;
}

@media screen and (min-width: 1200px) {
  .center {
    display: flex;
    flex-direction: row;
  }
  .space-text {
    padding-top: 180px;
  }
}

@media screen and (max-width: 1200px) {
  .center {
    display: flex;
    flex-direction: column;
  }

  .space-text {
    padding-top: 10px;
  }
}
