.click-nav-desktop {
  display: block;
}

.click-nav-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .click-nav-desktop {
    display: none;
  }

  .click-nav-mobile {
    display: block;
  }
}
