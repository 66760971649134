.for-mobile {
  display: none;
}

.for-desktop {
  display: block;
}

.nav-for-mobile-input {
  display: none;
}

.nav-for-desktop-input {
  display: block;
}

.nav-for-desktop {
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-for-mobile {
  display: none;
}

.navbar-logo {
  display: flex;
  justify-content: center;
  padding-top: 22px;
}

.navbar-logo-width {
  width: 60%;
}

.styleForText {
  color: #8a2be2;
  text-decoration: none;
  font-weight: normal;
}

.styleForTextTwo {
  color: black;
  text-decoration: none;
  font-weight: normal;
}

.my-courses-for-mobile {
  display: none;
}

.my-courses-for-desktop {
  display: block;
}

.for-space {
  padding-left: 10px;
  padding-top: 18px;
}

.styleforIcon {
  color: #8a2be2;
  font-size: 30px;
}

.styleforIconTwo {
  color: black;
  font-size: 30px;
}

.text-trojan {
  color: #8a2be2;
  font-weight: normal;
  text-align: start;
  padding-left: 25px;
  padding-bottom: 10px;
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .navbar-logo {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 22px;
  }

  .navbar-logo-width {
    width: 55%;
  }

  .nav-for-desktop {
    display: none;
  }

  .nav-for-mobile-input {
    display: block;
  }

  .nav-for-desktop-input {
    display: none;
  }

  .nav-for-mobile {
    display: flex;
    flex-direction: row;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .text-trojan {
    color: #8a2be2;
    font-weight: normal;
    text-align: start;
    padding-left: 25px;
    padding-bottom: 10px;
    font-size: 30px;
  }

  .styleForText {
    color: #8a2be2;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
  }

  .styleForTextTwo {
    color: black;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
  }

  .styleforIcon {
    color: #8a2be2;
    font-size: 26px;
  }

  .styleforIconTwo {
    color: black;
    font-size: 26px;
  }

  .my-courses-for-mobile {
    display: block;
  }

  .my-courses-for-desktop {
    display: none;
  }

  .for-space {
    padding-left: 10px;
    padding-top: 10px;
  }

  .close-menu {
    padding-right: 20px;
    padding-top: 5px;
    font-size: 40px;
  }

  .for-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .for-desktop {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .navbar-logo {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 22px;
  }

  .navbar-logo-width {
    width: 65%;
  }

  .text-trojan {
    color: #8a2be2;
    font-weight: normal;
    text-align: start;
    padding-left: 25px;
    padding-bottom: 10px;
    font-size: 30px;
  }

  .nav-for-desktop {
    display: none;
  }

  .nav-for-mobile {
    display: flex;
    flex-direction: row;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .styleForText {
    color: #8a2be2;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
  }

  .styleForTextTwo {
    color: black;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
  }

  .styleforIcon {
    color: #8a2be2;
    font-size: 26px;
  }

  .styleforIconTwo {
    color: black;
    font-size: 26px;
  }

  .my-courses-for-mobile {
    display: block;
  }

  .my-courses-for-desktop {
    display: none;
  }

  .for-space {
    padding-left: 10px;
    padding-top: 10px;
  }

  .close-menu {
    padding-right: 20px;
    padding-top: 16px;
    font-size: 30px;
  }

  .for-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .for-desktop {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .navbar-logo {
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 22px;
  }

  .navbar-logo-width {
    width: 65%;
  }

  .text-trojan {
    color: #8a2be2;
    font-weight: normal;
    text-align: start;
    padding-left: 25px;
    padding-bottom: 10px;
    font-size: 30px;
  }

  .styleForText {
    color: #8a2be2;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
  }

  .styleForTextTwo {
    color: black;
    text-decoration: none;
    font-weight: normal;
    font-size: 20px;
  }

  .styleforIcon {
    color: #8a2be2;
    font-size: 26px;
  }

  .styleforIconTwo {
    color: black;
    font-size: 26px;
  }

  .my-courses-for-mobile {
    display: block;
  }

  .my-courses-for-desktop {
    display: none;
  }
  .for-space {
    padding-left: 10px;
    padding-top: 10px;
  }

  .close-menu {
    padding-right: 15px;
    padding-top: 15px;
    font-size: 25px;
  }

  .for-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .for-desktop {
    display: none;
  }
}
