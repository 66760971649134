.main-block {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.center-block {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.fake-block {
  display: none;
}

.for-center-img {
  display: none;
}

.width-on-laptop {
  display: contents;
}

.name {
  font-size: 20px;
  font-weight: 500;
  padding-left: 10px;
}

.last-name-block {
  display: flex;
  justify-content: flex-start;
  padding-top: 5px;
}

.last-name {
  font-size: 18px;
}

.course-info {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: 30%;
  padding-top: 12px;
  font-size: 20px;
}

.img-size {
  width: 25%;
}

.ava-speaker {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 50%;
  margin-top: 4px;
  width: 80px;
  height: 80px;
}

.course-info-title-block {
  display: flex;
  justify-content: flex-start;
}

.title {
  padding-top: 40px;
}

.space-speaker {
  padding-top: 30px;
}

.space-speaker-name {
  padding-left: 5px;
  padding-top: 30px;
}

.switch-module {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border-width: 1px;
  border-color: #8a2be2;
  border-style: solid;
}

.switch-module-svg {
  margin-top: 1px;
}

.hide-img-speaker {
  display: block;
}

.block-speaker-img {
  display: flex;
  flex-direction: row;
}

.speaker-for-mobile {
  display: none;
}

@media screen and (min-width: 1700px) {
  .name {
    font-size: 20px;
    font-weight: 500;
    padding-left: 10px;
  }

  .last-name {
    font-size: 18px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 60%;
    padding-top: 12px;
  }
  .img-size {
    width: 30%;
  }

  .ava-speaker {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 50%;
    margin-top: 4px;
    width: 80px;
    height: 80px;
  }

  .title {
    padding-top: 30px;
  }

  .space-speaker {
    padding-top: 30px;
  }

  .space-speaker-name {
    padding-left: 5px;
    padding-top: 20px;
  }

  .switch-module {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }
}

@media screen and (max-width: 1700px) {
  .name {
    font-size: 20px;
    font-weight: 500;
    padding-left: 10px;
  }

  .last-name {
    font-size: 18px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 65%;
    padding-top: 12px;
  }
  .img-size {
    width: 30%;
  }

  .ava-speaker {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 50%;
    margin-top: 4px;
    width: 60px;
    height: 60px;
  }

  .title {
    padding-top: 10px;
  }

  .block-speaker-img {
    display: flex;
    flex-direction: row;
  }

  .space-speaker {
    padding-top: 15px;
  }

  .space-speaker-name {
    padding-left: 5px;
    padding-top: 10px;
  }

  .switch-module {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }

  .speaker-for-mobile {
    display: none;
  }
}

@media screen and (max-width: 1400px) {
  .name {
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
  }

  .last-name {
    font-size: 16px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 60%;
    padding-top: 8px;
  }
  .img-size {
    width: 30%;
  }

  .ava-speaker {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 50%;
    margin-top: 4px;
    width: 50px;
    height: 50px;
  }

  .title {
    padding-top: 5px;
    padding-bottom: 10px;
  }

  .space-speaker {
    padding-top: 14px;
  }

  .space-speaker-name {
    padding-left: 5px;
    padding-top: 8px;
  }

  .switch-module {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }
}

@media screen and (max-width: 1290px) {
  .space-speaker-name {
    padding-left: 5px;
    display: flex;
    text-align: start;
  }
}

@media screen and (max-width: 1213px) {
  .main-block {
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  .center-block {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .fake-block {
    display: none;
  }

  .width-on-laptop {
    display: contents;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    padding-left: 10px;
  }

  .last-name {
    font-size: 14px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 55%;
    padding-top: 12px;
  }
  .img-size {
    width: 33%;
  }

  .ava-speaker {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 50%;
    margin-top: 4px;
    width: 35px;
    height: 35px;
  }

  .title {
    padding-top: 0;
  }

  .space-speaker {
    padding-top: 5px;
  }

  .space-speaker-name {
    padding-left: 2px;
    padding-top: 2px;
  }

  .switch-module {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }

  .switch-module-svg {
    margin-top: 1px;
  }
}

@media screen and (max-width: 1120px) {
  .main-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  .fake-block {
    display: block;
    width: 30px;
    height: 30px;
  }

  .width-on-laptop {
    display: none;
  }

  .center-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }

  .for-center-img {
    display: flex;
    justify-content: center;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
  }

  .block-speaker-img {
    display: flex;
    flex-direction: row;
  }

  .hide-img-speaker {
    display: block;
    width: 50px;
    height: 50px;
  }

  .last-name-block {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .last-name {
    font-size: 12px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    padding-top: 0px;
  }
  .img-size {
    width: 35%;
  }

  .ava-speaker {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 50%;
    margin-top: 4px;
    width: 45px;
    height: 45px;
  }

  .course-info-title-block {
    display: flex;
    justify-content: center;
  }

  .title {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }

  .space-speaker {
    padding-top: 10px;
  }

  .space-speaker-name {
    padding-left: 2px;
    padding-top: 4px;
  }

  .switch-module {
    width: 30px;
    height: 25px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }

  .switch-module-svg {
    margin-top: -2px;
  }
}

@media screen and (max-width: 1000px) {
  .main-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  .fake-block {
    display: block;
    width: 30px;
    height: 30px;
  }

  .width-on-laptop {
    display: none;
  }

  .center-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }

  .for-center-img {
    display: flex;
    justify-content: center;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
  }

  .block-speaker-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .hide-img-speaker {
    display: none;
  }

  .last-name-block {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 8px;
  }

  .last-name {
    font-size: 13px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
    padding-top: 0px;
  }
  .img-size {
    width: 35%;
  }

  .ava-speaker {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 50%;
    margin-top: 1px;
    width: 30px;
    height: 30px;
  }

  .course-info-title-block {
    display: flex;
    justify-content: center;
  }

  .title {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }

  .space-speaker {
    padding-top: 7px;
  }

  .speaker-for-mobile {
    display: block;
  }

  .space-speaker-name {
    display: none;
  }

  .switch-module {
    width: 30px;
    height: 25px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }

  .switch-module-svg {
    margin-top: -2px;
  }
}

@media screen and (max-width: 665px) {
  .last-name {
    font-size: 10px;
  }
}

@media screen and (max-width: 400px) {
  .main-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
  }

  .fake-block {
    display: block;
    width: 30px;
    height: 30px;
  }

  .width-on-laptop {
    display: none;
  }

  .center-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    padding-top: 10px;
  }

  .for-center-img {
    display: flex;
    justify-content: center;
  }

  .name {
    font-size: 12px;
    font-weight: 500;
  }

  .block-speaker-img {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .hide-img-speaker {
    display: none;
  }

  .last-name-block {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 10px;
  }

  .last-name {
    font-size: 10px;
  }

  .course-info {
    display: flex;
    flex-direction: column;
    padding-left: 0px;
    width: 100%;
  }
  .img-size {
    width: 80%;
  }

  .course-info-title-block {
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
  }

  .title {
    display: flex;
    justify-content: center;
    padding-top: 0;
  }

  .space-speaker {
    padding-top: 7px;
  }

  .space-speaker-name {
    display: none;
  }

  .switch-module {
    width: 30px;
    height: 25px;
    border-radius: 100%;
    border-width: 1px;
    border-color: #8a2be2;
    border-style: solid;
  }

  .switch-module-svg {
    margin-top: -2px;
  }
}
