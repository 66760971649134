.size-title {
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
  text-align: flex-start;
}

.size-info {
  display: flex;
  text-align: flex-start;
  font-size: 15px;
}

@media screen and (max-width: 1500px) {
  .size-title {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 0;
    text-align: flex-start;
  }

  .size-info {
    display: flex;
    font-size: 13px;
    align-items: flex-start;
  }
}
