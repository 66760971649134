.size-answer {
  width: 40%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Значение "shadow-2xl" */
}

@media screen and (max-width: 1000px) {
  .size-answer {
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Значение "shadow-2xl" */
  }
}
