.size-my-courses {
  width: 80%;
}

.size-empty-img {
  width: 40%;
}

@media screen and (max-width: 1000px) {
  .size-my-courses {
    width: 98%;
  }

  .size-empty-img {
    width: 60%;
  }
}
