.switch-material {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.size-video {
  width: 80%;
}

.styleBtn {
  width: 33%;
  height: 40px;
  color: white;
  border-radius: 5px;
  background-color: #8a1fee;
}

.styleBtnTwo {
  width: 33%;
  height: 40px;
}

.switch-name {
  padding-top: 4px;
  padding-left: 10px;
  font-size: 22px;
}

.height-video {
  height: 500px;
}

.title-lesson {
  font-size: 30px;
}

.material-width {
  width: 80%;
  padding-top: 20px;
}

.questions-width {
  width: 80%;
  padding-top: 20px;
}

@media screen and (max-width: 1000px) {
  .switch-material {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .size-video {
    width: 100%;
  }

  .height-video {
    height: 500px;
  }

  .title-lesson {
    font-size: 25px;
  }

  .material-width {
    width: 95%;
    padding-top: 20px;
  }

  .questions-width {
    width: 95%;
    padding-top: 20px;
  }
}

@media screen and (max-width: 716px) {
  .switch-material {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .switch-name {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .switch-material {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .size-video {
    width: 100%;
  }

  .switch-name {
    display: none;
  }

  .height-video {
    height: 200px;
  }

  .title-lesson {
    font-size: 20px;
  }
}
